<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.row {
  
  width: 100%;
}

#contato {
  width: 100%;
}

#geral {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-wrap: normal;
}

#conteudo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1366px;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

*, *:before, *::after{
  box-sizing: border-box;
	scroll-behavior: smooth;
	word-wrap: break-word;
	word-break: break-all;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 9px !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}

.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 0 !important;
}

select {
  appearance: auto !important;
  -moz-appearance: auto !important;
  -webkit-appearance: auto !important;
}

a{
    text-decoration: none;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
</style>