import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'home',
    components: { default: () => import('../views/LandingPage.vue') }
  },
  {
    path: '/changelog',
    name: 'changelog',
    components: { default: () => import('../views/ChangeLog.vue') }
  },
  {
    path: '/resultados/',
    name: 'results',
    components: { default: () => import('../views/SearchResults.vue') },
    props: true
  },
  {
    path: '/detalhe/:id',
    name: 'detail',
    components: { default: () => import('../views/RentDetail.vue') },
    props: true
  },
  {
    path: '/login',
    name: 'login',
    components: { default: () => import('../views/LoginArea.vue') },
    props: true
  },
  {
    path: '/previsao-preco',
    name: 'previsao-preco',
    components: { default: () => import('../views/PricePrediction.vue') },
    props: true
  },
  {
    path: '/cadastro',
    components: { default: () => import('../views/ClientRegister.vue') },
    props: true,
    children: [
      {
        path: '',
        name: 'basic',
        components: {
          default: () => import('../components/FormRegisterBasic.vue')
        }
      },
      {
        path: 'completo',
        name: 'completo',
        components: {
          default: () => import('../components/FormRegister.vue')
        }
      },
      {
        path: 'documentos',
        name: 'documentos',
        components: {
          default: () => import('../components/FormRegisterDocuments.vue')
        },
      },
      {
        path: 'documentos-contrato',
        name: 'documentosContrato',
        components: {
          default: () => import('../components/FormRegisterDocumentsContrato.vue')
        },
      },
      {
        path: 'password',
        name: 'password',
        components: {
          default: () => import('../components/FormRegisterPassword.vue')
        }
      }
    ]
  },
  {
    path: '/recuperar-senha',
    name: 'recuperar-senha',
    components: { default: () => import('../views/PasswordRecovery.vue') },
    props: true
  },
  {
    path: '/confirmar-senha',
    name: 'confirmar-senha',
    components: { default: () => import('../views/PasswordConfirm.vue') },
    props: true
  },
  {
    path: '*',
    name: 'erro-404',
    components: { default: () => import('../views/PageNotFound.vue') },
    props: true
  },
  {
    path: '/dados-complementares',
    components: { default: () => import('../views/ComplementaryInfo.vue') },
    props: true,
    children: [
      {
        path: '',
        name: 'dados-complementares',
        components: {
          default: () => import('../components/FormRegister.vue')
        }
      },
      {
        path: 'valores',
        name: 'valores',
        components: {
          default: () => import('../components/ValoresTotais.vue')
        }
      },
    ]
  },
  {
    path: '/area-logada',
    components: { default: () => import('../views/LoggedArea.vue') },
    props: true,
    children: [
      {
        path: '',
        name: 'area-logada',
        default: true,
        components: {
          default: () => import('../components/FavoriteProperties.vue')
        },
      },
      {
        name: 'minhas-propostas-locador',
        path: 'minhas-propostas-locador',
        components: {
          default: () => import('../components/MinhasPropostasLocador.vue')
        }
      },
      {
        name: 'minhas-propostas-locatario',
        path: 'minhas-propostas-locatario',
        components: {
          default: () => import('../components/MinhasPropostasLocatario.vue')
        }
      },
      {
        path: 'favoritos',
        name: 'favoritos',
        components: {
          default: () => import('../components/FavoriteProperties.vue')
        }
      },
      {
        path: 'analise',
        name: 'analise',
        components: {
          default: () => import('../components/PropertiesAnalysis.vue')
        }
      },
      {
        path: 'meus-imoveis',
        name: 'meus-imoveis-locatario',
        components: {
          default: () => import('../components/MyProperties.vue')
        }
      },
      {
        path: 'meus-imoveis-locador',
        name: 'meus-imoveis-locador',
        components: {
          default: () => import('../components/MyProperties2.vue')
        }
      },
      {
        path: 'imoveis-cadastrados',
        name: 'imoveis-cadastrados',
        components: {
          default: () => import('../components/RegisteredProperties.vue')
        }
      },
      {
        path: 'aprovacao',
        name: 'aprovacao',
        components: {
          default: () => import('../components/CadastralApproval.vue')
        }
      },
      {
        path: 'vistoria-locador',
        name: 'vistoria-locador',
        components: {
          default: () => import('../components/SurveyAp.vue')
        }
      },
      {
        path: 'vistoria-locatario',
        name: 'vistoria-locatario',
        components: {
          default: () => import('../components/SurveyAp.vue')
        }
      },
      {
        path: 'controle-chaves',
        name: 'controle-chaves',
        components: {
          default: () => import('../components/ControlKeys.vue')
        }
      },
      {
        path: 'cadastro-imoveis',
        name: 'cadastro-imoveis',
        components: {
          default: () => import('../components/FormRegisterImovel.vue')
        }
      },
      {
        path: 'cadastro-imoveis-detalhes',
        name: 'cadastro-imoveis-detalhes',
        components: {
          default: () => import('../components/FormRegisterImovelDetails.vue')
        }
      },
      {
        path: 'cadastro-imoveis-fotos',
        name: 'cadastro-imoveis-fotos',
        components: {
          default: () => import('../components/FormRegisterImovelFotos.vue')
        }
      },
      {
        path: 'agendar-visitas',
        name: 'agendar-visitas',
        components: {
          default: () => import('../components/AgendarVisitas.vue')
        }
      },
      {
        path: 'visitas-agendadas-locatario',
        name: 'visitas-agendadas-locatario',
        components: {
          default: () => import('../components/VisitasAgendadasLocatario.vue')
        }
      },
      {
        path: 'visitas-agendadas-locador',
        name: 'visitas-agendadas-locador',
        components: {
          default: () => import('../components/VisitasAgendadasLocador.vue')
        }
      },
      {
        path: 'painel-financeiro-locatario',
        name: 'painel-financeiro-locatario',
        components: {
          default: () => import('../components/PainelFinanceiroLocatario.vue')
        }
      },
      {
        path: 'painel-financeiro-locador',
        name: 'painel-financeiro-locador',
        components: {
          default: () => import('../components/PainelFinanceiroLocador.vue')
        }
      },
      {
        path: 'assina-contrato',
        name: 'assina-contrato',
        components: {
          default: () => import('../components/AssinaturaDeContrato.vue')
        }
      },
      {
        path: 'extrato-financeiro-locatario',
        name: 'extrato-financeiro-locatario',
        components: {
          default: () => import('../components/ExtratoFinanceiroLocatario.vue')
        }
      },
      {
        path: 'extrato-financeiro-locador',
        name: 'extrato-financeiro-locador',
        components: {
          default: () => import('../components/ExtratoFinanceiroLocador.vue')
        }
      },
      {
        path: 'processo-aluguel',
        name: 'processo-aluguel',
        components: {
          default: () => import('../components/ProcessoAluguel.vue')
        },
      },
      {
        path: 'documentos-proposta',
        name: 'documentos-proposta',
        components: {
          default: () => import('../components/FormRegisterDocumentsContrato.vue')
        }
      },
      // {
      //   path: 'seguro-fianca',
      //   name: 'seguro-fianca',
      //   components: {
      //     default: () => import('../components/BailInsurance.vue')
      //   }
      // },
      {
        path: 'seguro-incendio',
        name: 'seguro-incendio',
        components: {
          default: () => import('../components/FireInsurance.vue')
        }
      },
      {
        name: 'iptu',
        path: 'iptu',
        components: {
          default: () => import('../components/IptuFee.vue')
        }
      },
      {
        name: 'condominio',
        path: 'condominio',
        components: {
          default: () => import('../components/CondominiumFee.vue')
        }
      },
      {
        name: 'contrato',
        path: 'contrato',
        components: {
          default: () => import('../components/ObservationField.vue')
        }
      },
      {
        name: 'status-component100',
        path: 'status-component100',
        components: {
          default: () => import('../components/StatusComponent100.vue')
        }
      },
      {
        name: 'status-component101',
        path: 'status-component101',
        components: {
          default: () => import('../components/StatusComponent101.vue')
        }
      },
      {
        name: 'status-component102',
        path: 'status-component102',
        components: {
          default: () => import('../components/StatusComponent102.vue')
        }
      },
      {
        name: 'status-component103',
        path: 'status-component103',
        components: {
          default: () => import('../components/StatusComponent103.vue')
        }
      },
      {
        name: 'status-component104',
        path: 'status-component104',
        components: {
          default: () => import('../components/StatusComponent104.vue')
        }
      },
      {
        name: 'status-component105',
        path: 'status-component105',
        components: {
          default: () => import('../components/StatusComponent105.vue')
        }
      },
      {
        name: 'status-component106',
        path: 'status-component106',
        components: {
          default: () => import('../components/StatusComponent106.vue')
        }
      },
      {
        name: 'status-component107',
        path: 'status-component107',
        components: {
          default: () => import('../components/StatusComponent107.vue')
        }
      },
      {
        name: 'status-component108',
        path: 'status-component108',
        components: {
          default: () => import('../components/StatusComponent108.vue')
        }
      },
      {
        name: 'status-component998',
        path: 'status-component998',
        components: {
          default: () => import('../components/StatusComponent998.vue')
        }
      },
      {
        name: 'status-component999',
        path: 'status-component999',
        components: {
          default: () => import('../components/StatusComponent999.vue')
        }
      },
      {
        name: 'status-component10',
        path: 'status-component10',
        components: {
          default: () => import('../components/StatusComponent10.vue')
        }
      },
    ]
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/dados-complementares/',
    '/dados-complementares/documentos-contrato/', 
    '/dados-complementares/seguro-fianca/',
    '/dados-complementares/seguro-incendio/',
    '/dados-complementares/iptu/',
    '/dados-complementares/condominio/',
    '/dados-complementares/observacoes/', 
    '/area-logada', 
    '/area-logada/favoritos/',
    '/area-logada/analise/',
    //'/area-logada/seguro-fianca', '/area-logada/seguro-fianca/'
    '/area-logada/meus-imoveis/',
    '/area-logada/meus-imoveis-locador/',
    '/area-logada/imoveis-cadastrados/',
    '/area-logada/cadastro-imoveis/',
    '/area-logada/aprovacao/',
    '/area-logada/vistoria-locador/',
    '/area-logada/vistoria-locatario/',
    '/area-logada/controle-chaves/',
    '/area-logada/agendar-visitas/',
    '/area-logada/valores/',
    '/area-logada/painel-financeiro-locatario/',
    '/area-logada/painel-financeiro-locador/',
    '/area-logada/extrato-financeiro-locatario/',
    '/area-logada/extrato-financeiro-locador/',
    '/area-logada/processo-aluguel/',
    '/area-logada/documentos-proposta/',
    '/area-logada/seguro-incendio/',
    '/area-logada/iptu/',
    '/area-logada/condominio/',
    '/area-logada/contrato/',
    '/area-logada/minhas-propostas-locador/',
    '/area-logada/minhas-propostas-locatario/',
    '/area-logada/assina-contrato/',
    '/area-logada/visitas-agendadas-locatario/',
    '/area-logada/visitas-agendadas-locador/',
    '/area-logada/cadastro-imoveis-detalhes/',
    '/area-logada/cadastro-imoveis-fotos/',
    '/area-logada/status-component100/',
    '/area-logada/status-component101/',
    '/area-logada/status-component102/',
    '/area-logada/status-component103/',
    '/area-logada/status-component104/',
    '/area-logada/status-component105/',
    '/area-logada/status-component106/',
    '/area-logada/status-component107/',
    '/area-logada/status-component108/',
    '/area-logada/status-component998/',
    '/area-logada/status-component999/',
  ];
  const authRequired = publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('session');
  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
})

export default router
