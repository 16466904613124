import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import GoogleAuth from '@/config/google_oAuth.js'
import VueMask from 'v-mask'
import './registerServiceWorker'
import VMoney from 'v-money';

let ambiente = 'hml';

let baseURL = "";
let apiURL = "";

if (ambiente === 'prod') {
  baseURL = 'https://api-vu-dev.vitrineurbana.com.br/VitrineUrbana/';
  apiURL = 'https://3.84.57.108:5000/';
  axios.defaults.headers.common['a'] = 'VUDEV';
} else if (ambiente === 'hml') {
  baseURL = 'https://api-vu-dep.vitrineurbana.com.br/VitrineUrbana/';
  apiURL = 'https://3.84.57.108:5000/';
  axios.defaults.headers.common['a'] = 'HML';
}

axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['debug'] = false;
axios.defaults.headers.common['k'] = store.state.loginUser;
axios.defaults.headers.common['u'] = '1';

Vue.prototype.$http = axios;

const machineLearningAPI = axios.create({
  baseURL: apiURL,
});

machineLearningAPI.defaults.headers.common = {};

Vue.prototype.$machineLearningAPI = machineLearningAPI;

// Determine the allowed origins based on the environment
const allowedParentOrigin = ambiente === 'prod' 
  ? 'https://front.vitrineurbana.com.br/' 
  : 'http://localhost:8081/';

const gauthOption = {
  clientId: '1098283498978-cevqc40r3tfkg485ggi6j6oc5bit9akb.apps.googleusercontent.com',
  prompt: 'select_account',
  scope: 'openid email profile',
  allowed_parent_origin: allowedParentOrigin,
  callback: (tokenResponse) => {
    console.log(tokenResponse);
    store.commit('setToken', tokenResponse);
  }
};

Vue.use(GoogleAuth, gauthOption);
Vue.use(VueMask);
Vue.use(VMoney, { precision: 2 });

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App),
}).$mount('#app');

// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// import vuetify from './plugins/vuetify'
// import axios from 'axios'
// import GoogleAuth from '@/config/google_oAuth.js'
// import VueMask from 'v-mask'
// import './registerServiceWorker'

// const ambiente = 'dev'; // Troque para 'hml' ou 'prod' conforme necessário

// let baseURL = "";

// if (ambiente === 'prod') {
//   baseURL = "https://api-vu-prod.vitrineurbana.com.br/VitrineUrbana/";
//   axios.defaults.headers.common['a'] = 'VUDEV';
// } else if (ambiente === 'hml') {
//   baseURL = "https://api-vu-dep.vitrineurbana.com.br/VitrineUrbana/";
//   axios.defaults.headers.common['a'] = 'HML';
// } else {
//   // Lidar com outros ambientes, se necessário
// }

// axios.defaults.baseURL = baseURL;

// axios.defaults.headers.common['debug'] = false
// axios.defaults.headers.common['k'] = store.state.loginUser
// axios.defaults.headers.common['u'] = '1'

// Vue.prototype.$http = axios
// const machineLearningAPI = axios.create({
//   baseURL: baseURL,
// });

// machineLearningAPI.defaults.headers.common = {};

// Vue.prototype.$machineLearningAPI = machineLearningAPI

// const gauthOption = {
//   clientId: '411484706040-3agt660mgp6iap9armgsc7ktm9mh2al9.apps.googleusercontent.com',
//   prompt: 'select_account',
//   scope: 'openid email profile',
//   allowed_parent_origin: 'http://localhost:8080',
//   callback: (tokenResponse) => {
//     console.log(tokenResponse)
//     store.commit('setToken', tokenResponse)
//   }
// }

// Vue.use(GoogleAuth, gauthOption)
// Vue.use(VueMask)

// new Vue({
//   router,
//   store,
//   vuetify,
//   axios,
//   render: h => h(App)
// }).$mount('#app')

// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// import vuetify from './plugins/vuetify'
// import axios from 'axios'
// import GoogleAuth from '@/config/google_oAuth.js'
// import VueMask from 'v-mask'
// import './registerServiceWorker'


// const ambiente = 'prod'
// axios.defaults.baseURL = 'https://api-vu-dep.vitrineurbana.com.br'
// axios.defaults.headers.common['debug'] = false
// axios.defaults.headers.common['k'] = store.state.loginUser
// // axios.defaults.headers.common['k'] = 'ce11fce876c93ed5d2a72da660496473'
// axios.defaults.headers.common['a'] = 'HML'
// axios.defaults.headers.common['u'] = '1'

// Vue.prototype.$http = axios
// let baseURL = "https://3.84.57.108:5000/"

// if (ambiente === 'prod') {
//   baseURL = "https://3.84.57.108:5000/"
// } else {
//   baseURL = "https://127.0.0.1:5000/"
// }

// const machineLearningAPI = axios.create({
//   baseURL: baseURL,
// });

// machineLearningAPI.defaults.headers.common = {};

// Vue.prototype.$machineLearningAPI = machineLearningAPI

// const gauthOption = {
//   clientId: '411484706040-3agt660mgp6iap9armgsc7ktm9mh2al9.apps.googleusercontent.com',
//   prompt: 'select_account',
//   scope: 'openid email profile',
//   allowed_parent_origin: 'http://localhost:8080',
//   callback: (tokenResponse) => { }
// }

// Vue.use(GoogleAuth, gauthOption)
// Vue.use(VueMask)

// new Vue({
//   router,
//   store,
//   vuetify,
//   axios,
//   render: h => h(App)
// }).$mount('#app')
