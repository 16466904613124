import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore } from '@/config/utils'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
const session = getStore('session')
const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : '63a9f0ea7bb98050796b649e85481845'


export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage,
  })],

  namespaced: true,
  state: {
    redirectUrl: "http://wwww",
    // loginUser: '63a9f0ea7bb98050796b649e85481845', //session,
    loginUser: user,
    userId: undefined,
    email: null,
    password: null,
    isLogin: false,
    isRegister: false,
    proposta: null,
    imovelVisita: null,
  },
  mutations: {
    setLoginUser(state, session) {
      state.loginUser = session
      setStore('session', session)
    },
    SET_ID(state, id) {
      state.userId = id;
    },
    SET_PROPOSTA(state, proposta) {
      state.proposta = proposta;
    },
    SET_IMOVEL_VISITA(state, imovel) {
      state.imovelVisita = imovel;
    },
    RESET_STATE(state) {
      state.loginUser = null;
      state.userId = null;
      state.proposta = null;
    },
  },
  actions: {
    async setId({ commit }, id) {
      commit('SET_ID', id)
    },
    async setProposta({ commit }, proposta) {
      commit('SET_PROPOSTA', proposta)
    },
    async setImovelVisita({ commit }, imovel) {
      commit('SET_IMOVEL_VISITA', imovel)
    },
    // Adicione uma ação para limpar os registros e redefinir o estado
    async limparDadosPersistentes({ commit, dispatch }) {
      // Chame a ação de resetar o estado
      dispatch('limparRegistros');

      // Limpe explicitamente os dados persistentes
      localStorage.removeItem('vuex');

      // Reinicie o estado do Vuex
      commit('RESET_STATE');
    },
  },
  getters: {
    getLoginUserInfo(state) {
      return state.loginUser
    },
    getUserId(state) {
      return state.userId
    },
    getProposta(state) {
      return state.proposta
    },
    getImovelVisita(state) {
      return state.imovelVisita
    }
  },
  modules: {
  }
})


